// rewrite bootstrap variable here
// $icon-font-path: '../node_modules/bootstrap-sass/assets/fonts/bootstrap/';

// $odissea-secondary-color: #398bce;
$font-family-sans-serif: 'Open Sans', -apple-system, BlinkMacSystemFont,
  'Segoe UI', Arial, 'Helvetica Neue', Helvetica, sans-serif !default;
// $link-color: #000;
// $link-hover-decoration: none;
// $link-hover-color: #398bce;

// $modal-header-border-color: transparent;
// $modal-content-border-color: transparent;

// $input-btn-focus-width: 0;
// $enable-shadows: false;

$modal-md: 690px;
$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-height: 66px;
$sidebar-width-medium: 200px;
$sidebar-width-large: 300px;
$footer-height: 67px;
$navbar-height-md: 60px;
$media-min-width-breakdown: 1299px;
@import '../../node_modules/bootstrap-scss/functions';
@import '../../node_modules/bootstrap-scss/variables';

// Animation
$fade-timer: 200ms;
$scale-timer: 100ms;

// Font
$open-font: 'Open Sans';
$bnp-font: 'BNPPSans', sans-serif;

// Colors
// Black and white shades
$light-black: #222;
$black: #000;
$white: #fff;
$bg-lightest-grey: #f0f0f0;
$bg-light-grey: #dedede;
$bg-medium-grey: #c3c3c3;
$txt-dark-grey: #343434;
$txt-medium-grey: #5b5b5b;
$outline-grey: #888;
// Colors
$light-violet: #b08bbc;
$violet: #8164a1;
$pink: #e41d90;
$pink: #e41d90;
$red: #922735;
$light-red: #c6263c;
$brown: #ac7b2f;
$yellow: #ffcd00;
$dark-yellow: #dac73f;
$orange: #d76832;
$light-orange: #fc6a13;
$lightest-orange: #f7a04a;
$lightest-green: #d0edeb;
$light-green: #50b9b1;
$medium-green: #7ac8a2;
$green: #35b783;
$lime: #86CA4B;
$darkest-green: #223e3b;
$dark-green: #00685e;
$green-blue: #2c7e89;
$blue: #177396;
$light-blue: #328fbf;
// Body bootstrap override
$body-bg: #fff;
$body-color: $black;

// Link bar
$link-bar-width: 80px;

$color-map: (
  light-black: $light-black,
  grey: $txt-medium-grey,
  black: $black,
  white: $white,
  light-violet: $light-violet,
  violet: $violet,
  pink: $pink,
  red: $red,
  brown: $brown,
  yellow: $yellow,
  dark-yellow: $dark-yellow,
  orange: $orange,
  light-orange: $lightest-orange,
  lightest-green: $lightest-green,
  light-green: $light-green,
  medium-green: $medium-green,
  green: $green,
  darkest-green: $darkest-green,
  dark-green: $dark-green,
  green-blue: $green-blue,
  blue: $blue,
  light-blue: $light-blue,
  lime: $lime
);
