@import 'variables';

.text-light-violet {
  color: $light-violet;
}

.text-violet {
  color: $violet;
}

.text-pink {
  color: $pink;
}

.text-red {
  color: $red;
}

.text-brown {
  color: $brown;
}

.text-yellow {
  color: $dark-yellow;
}

.text-orange {
  color: $orange;
}

.text-light-orange {
  color: $lightest-orange;
}

.text-light-green {
  color: $light-green;
}

.text-green {
  color: $green;
}

.text-dark-green {
  color: $dark-green;
}

.text-blue {
  color: $blue;
}

.text-light-blue {
  color: $light-blue;
}

.text-lime {
  color: $lime;
}

.background-light-violet {
  background-color: $light-violet;
}

.background-violet {
  background-color: $violet;
}

.background-pink {
  background-color: $pink;
}

.background-red {
  background-color: $red;
}

.background-brown {
  background-color: $brown;
}

.background-yellow {
  background-color: $dark-yellow;
}

.background-orange {
  background-color: $orange;
}

.background-light-orange {
  background-color: $lightest-orange;
}

.background-light-green {
  background-color: $light-green;
}

.background-green {
  background-color: $green;
}

.background-dark-green {
  background-color: $dark-green;
}

.background-blue {
  background-color: $blue;
}

.background-light-blue {
  background-color: $light-blue;
}

.background-lime {
  background-color: $lime;
}
