@import 'variables';

.app-main {
  ngx-extended-pdf-viewer {
    .toolbar {
      border: none;
    }

    #toolbarContainer {
      background-color: $light-black;
    }

    svg {
      color: white;
    }

    select,
    .toolbarField {
      background-color: $bg-medium-grey !important;
      color: $white;
      border: none !important;
    }

    .page {
      box-sizing: content-box;
    }
  }
}
