
.flex-row {
	display: flex !important;
	flex-direction: row !important;
}
.flex-row-reverse {
	display: flex !important;
	flex-direction: row-reverse !important;
}
.flex-column {
	display: flex !important;
	flex-direction: column !important;
}
.flex-column-reverse {
	display: flex !important;
	flex-direction: column-reverse !important;
}

.flex-1 {
	flex: 1;
}

.justify-content-start {
	justify-content: flex-start !important;
}
.justify-content-end {
	justify-content: flex-end !important;
}
.justify-content-center {
	justify-content: center !important;
}
.justify-content-between {
	justify-content: space-between !important;
}
.justify-content-evenly {
	justify-content: space-evenly !important;
}

.align-items-start {
	align-items: flex-start !important;
}
.align-items-end {
	align-items: flex-end !important;
}
.align-items-center {
	align-items: center !important;
}
.align-items-baseline {
	align-items: baseline !important;
}

