@import 'styles/variables';
@import 'styles/pdf-viewer';
@import 'node_modules/bootstrap-scss/mixins';
@import 'node_modules/bootstrap-scss/root';
@import 'node_modules/bootstrap-scss/reboot';
@import 'node_modules/bootstrap-scss/type';
//@import 'node_modules/bootstrap-scss/images';
@import 'node_modules/bootstrap-scss/code';
@import 'node_modules/bootstrap-scss/grid';
@import 'node_modules/bootstrap-scss/tables';
@import 'node_modules/bootstrap-scss/forms';
@import 'node_modules/bootstrap-scss/buttons';
@import 'node_modules/bootstrap-scss/transitions';
@import 'node_modules/bootstrap-scss/dropdown';
@import 'node_modules/bootstrap-scss/button-group';
@import 'node_modules/bootstrap-scss/input-group';
@import 'node_modules/bootstrap-scss/custom-forms';
@import 'node_modules/bootstrap-scss/nav';
@import 'node_modules/bootstrap-scss/navbar';
@import 'node_modules/bootstrap-scss/card';
// @import 'node_modules/bootstrap-scss/breadcrumb';
// @import 'node_modules/bootstrap-scss/pagination';
// @import 'node_modules/bootstrap-scss/badge';
// @import 'node_modules/bootstrap-scss/jumbotron';
@import 'node_modules/bootstrap-scss/alert';
@import 'node_modules/bootstrap-scss/progress';
// @import 'node_modules/bootstrap-scss/media';
@import 'node_modules/bootstrap-scss/list-group';
// @import 'node_modules/bootstrap-scss/close';
@import 'node_modules/bootstrap-scss/modal';
// @import 'node_modules/bootstrap-scss/tooltip';
// @import 'node_modules/bootstrap-scss/popover';
// @import 'node_modules/bootstrap-scss/carousel';
@import 'node_modules/bootstrap-scss/spinners';
@import 'node_modules/bootstrap-scss/utilities';
@import 'node_modules/bootstrap-scss/print';
@import 'styles/toaster';
@import 'styles/colors';
@import 'styles/flex';
// Style for @ng-select/ng-select
@import '~@ng-select/ng-select/themes/default.theme.css';

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
//$fa-font-path: '../node_modules/@fortawesome/fontawesome-free/webfonts';
@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import 'node_modules/@fortawesome/fontawesome-free/scss/regular.scss';

@font-face {
  font-family: 'BNPPSans';
  font-weight: 400;
  font-style: normal;
  src: url('assets/fonts/bnppsansreg/BNPPSans.eot');
  src: url('assets/fonts/bnppsansreg/BNPPSans.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/bnppsansreg/BNPPSans.woff2') format('woff2'),
    url('assets/fonts/bnppsansreg/BNPPSans.woff') format('woff'),
    url('assets/fonts/bnppsansreg/BNPPSans.ttf') format('truetype'),
    url('assets/fonts/bnppsansreg/BNPPSans.svg#BNPPSans') format('svg');
}

@font-face {
  font-family: 'BNPPSans';
  font-weight: 700;
  font-style: normal;
  src: url('assets/fonts/bnppsansbold/BNPPSansBold.eot');
  src: url('assets/fonts/bnppsansbold/BNPPSansBold.eot')
      format('embedded-opentype'),
    url('assets/fonts/bnppsansbold/BNPPSansBold.woff2') format('woff2'),
    url('assets/fonts/bnppsansbold/BNPPSans-Bold.woff') format('woff'),
    url('assets/fonts/bnppsansbold/BNPPSansBold.ttf') format('truetype'),
    url('assets/fonts/bnppsansbold/BNPPSans-Bold.svg#BNPPSans') format('svg');
}

@font-face {
  font-family: 'BNPPSans';
  font-weight: 800;
  font-style: normal;
  src: url('assets/fonts/bnppsansextrabold/BNPPSansExtraBold.eot');
  src: url('assets/fonts/bnppsansextrabold/BNPPSansExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/bnppsansextrabold/BNPPSansExtraBold.woff2')
      format('woff2'),
    url('assets/fonts/bnppsansextrabold/BNPPSansExtraBold.woff') format('woff'),
    url('assets/fonts/bnppsansextrabold/BNPPSansExtraBold.ttf')
      format('truetype'),
    url('assets/fonts/bnppsansextrabold/BNPPSansExtraBold.svg#BNPPSans')
      format('svg');
}

@font-face {
  font-family: 'BNPPSans';
  font-weight: 300;
  font-style: normal;
  src: url('assets/fonts/bnppsanslight/BNPPSansLight.eot');
  src: url('assets/fonts/bnppsanslight/BNPPSansLight.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/bnppsanslight/BNPPSansLight.woff2') format('woff2'),
    url('assets/fonts/bnppsanslight/BNPPSans-Light.woff') format('woff'),
    url('assets/fonts/bnppsanslight/BNPPSansLight.ttf') format('truetype'),
    url('assets/fonts/bnppsanslight/BNPPSans-Light.svg#BNPPSans') format('svg');
}

@font-face {
  font-family: 'ArialRoundedMTBold';
  font-weight: normal;
  font-style: normal;
  src: url('assets/fonts/Roboto-bold/Roboto-bold.eot');
  src: url('assets/fonts/Roboto-bold/Roboto-bold.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/Roboto-bold/Roboto-bold.woff') format('woff'),
    url('assets/fonts/Roboto-bold/Roboto-bold.ttf') format('truetype'),
    url('assets/fonts/Roboto-bold/Roboto-bold.svg#Roboto') format('svg');
  // TODO : replace with HelveticaNeue font
}

// TODO : if needed, import variants of HelveticaNeue

:focus {
  outline: 0 !important;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

/**** General styles ****/
body {
  position: relative;
  width: 100%;
  min-height: 100%;
  background-color: $body-bg;
}

button {
  border: none;
}

a {
  outline: none;

  &:focus {
    outline: none;
  }
}

h2,
h3 {
  font-family: $bnp-font;
  font-weight: 800;
  font-size: 22px;
  line-height: 33px;
}

h2 {
  color: $green;
}

h3 {
  color: $light-green;
}

h5 {
  font-size: 20px;
  font-style: italic;
  line-height: 28px;
  color: #f52864;
}

.modal-backdrop.show {
  z-index: 1340;
}

.modal.show {
  z-index: 1350;
}

.modal-content {
  border-radius: 1rem;
  border: none;

  @include media-breakpoint-down(sm) {
    max-width: 100vh;
    max-height: 100vh;
    border-radius: 0;
  }

  span.badge-description {
    font-size: 12px !important;
  }
}

.modal-dialog {
  @include media-breakpoint-down(sm) {
    max-width: 100%;
    margin: 0;
    border-radius: 0;
  }
}

.modal-header {
  @include media-breakpoint-down(sm) {
    border-radius: 0 !important;
  }
}

.radio-inline {
  .control-label {
    cursor: pointer;
  }
}

//@import '../../../../_variables.scss';
//@import '../../../../../node_modules/bootstrap-scss/mixins/breakpoints';

.pre-home {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  background-color: $white;

  &.with-navbar {
    min-height: calc(100vh - 80px);

    @include media-breakpoint-down(md) {
      min-height: calc(100vh - 60px);
    }
  }

  .main-titles-container {
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    //background-image: url('assets/images/prehome_big.jpg');
    background-color: $light-green;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;

    @include media-breakpoint-down(md) {
      display: none;
    }

    .main-titles {
      width: 90%;
      max-width: 450px;

      h1 {
        font-family: $bnp-font;
        font-weight: 800;
        color: $white;
        font-size: 35px;
        text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
        text-transform: uppercase;
        line-height: 41px;
      }

      p {
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
        color: $white;
        text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
      }
    }

    .shape {
      position: absolute;

      &.small_bottom_left {
        bottom: 110px;
        left: 350px;
        width: 40px;
      }

      &.medium_bottom_left {
        bottom: 180px;
        left: 120px;
        width: 160px;
      }

      &.big_bottom_left {
        bottom: -40px;
        left: -20px;
        width: 350px;
      }

      &.small_top_right {
        right: 80px;
        top: 190px;
        width: 40px;
      }

      &.medium_top_right {
        top: 125px;
        right: 255px;
      }

      &.big_top_right {
        top: -50px;
        right: 40px;
        width: 250px;
      }

      &.small_bottom_right {
        bottom: 200px;
        right: 140px;
        width: 40px;
      }

      &.medium_bottom_right {
        bottom: 100px;
        right: 150px;
      }
    }
  }

  .form-container {
    max-width: 500px;
    width: 100%;

    @include media-breakpoint-down(md) {
      max-width: unset;
    }

    .logos-wrapper {
      margin-top: 120px;
      height: 124px;
      display: flex;
      align-items: center;
      max-width: 360px;
      margin: 0 auto;

      img {
        max-width: 40%;
        max-height: 60px;
      }
    }

    .welcome-texts-container {
      width: 100%;
      padding: 32px 0;
      //background-color: $light-green;

      .welcome-texts {
        width: 95%;
        max-width: 360px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        h2 {
          margin-bottom: 0;
          font-family: $bnp-font;
          font-size: 22px;
          font-weight: 700;
          line-height: 28px;
          text-align: left;
          color: black;
        }

        p {
          font-size: 15px;
          line-height: 22px;
          color: $black;
          margin: 8px 0 0 0;
        }
      }
    }
  }
}

.form-profile {
  .name {
    padding-left: 10%;
    width: 95%;
    margin: 0 auto;
    color: #50b9b1;
    font-family: $bnp-font;
    font-size: 27px;
    margin-top: 50px;
  }
  .form-wrapper {
    .custom-field {
      select,
      input,
      .ng-select.custom {
        background-color: white;

        &.ng-select-disabled {
          background-color: lightgray;
        }
      }
    }

    .submit-container {
      justify-content: center;

      .edit {
        padding: 3px 11px 6px;
        border-radius: 19px;
        margin: 10px 0 5px;
        color: white;
        background-color: #14dca0;

        &.loading {
          background-color: #e3e3e3;
          padding: 3px 60px 6px;
        }
      }
    }
  }
}

.loader {
  display: block;
  border: 3px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  border-top: 3px solid #f52864;
  border-bottom: 3px solid #14dca0;
  width: 20px;
  height: 20px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form-wrapper {
  width: 95%;
  /*max-width: 360px;*/
  padding-left: 10%;
  margin: 0 auto;
  padding-top: 16px;

  .custom-field {
    position: relative;
    width: 100%;
    margin: 32px 0 0 0;
    transition: 0.3s;

    &.disabled {
      label {
        background-color: lightgray;
      }
    }

    label {
      font-family: $bnp-font;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 700;
    }

    /*
    label {
      position: absolute;
      top: 0;
      left: 20px;
      margin-bottom: 0;
      padding: 0 8px;
      font-family: $bnp-font;
      font-size: 14px;
      line-height: 16px;
      transform: translateY(-50%);
      background-color: $white;
      z-index: 5;


      &.profile {
        top: -2px;
        padding: 4px 8px;
        border-radius: 4px;
      }
    }
    */

    select,
    input,
    .ng-select.custom {
      width: 100%;
      height: 30px;
      padding-left: 4px;
      border-radius: 4px;
      border: 1px $bg-medium-grey solid;
      background-color: transparent;

      @include media-breakpoint-down(md) {
        width: 95%;
      }
    }

    .ng-value-label {
      font-size: 11px;
      font-family: $open-font;
      font-weight: 600;
      color: $dark-green;
    }

    .ng-select.custom {
      .ng-select-container {
        height: 28px;
        line-height: 28px;
        min-height: 28px;
        border: 0;
        background-color: transparent;
        box-shadow: unset;
      }

      .ng-input {
        top: 0;

        input {
          padding-left: 0;
        }
      }
    }
  }

  .submit-container {
    width: 95%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .custom-submit {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      margin: 28px 0 32px;
      padding: 3px 32px 0;
      background-color: $light-green;
      color: $white;
      text-transform: uppercase;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.3s;
      font-family: $bnp-font;
      font-weight: 700;

      &.disabled {
        background-color: #e3e3e3;
        color: $txt-medium-grey;
        cursor: not-allowed;
      }
    }
  }

  .error {
    margin: 8px 0 0 16px;
    color: red;
    font-size: 12px;
    font-weight: bold;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.btn-link {
  color: black;
  text-decoration: underline;

  &:hover {
    color: #14dca0;
  }
}

h4.subtitle-popup,
.since-last-visit {
  font-family: $bnp-font;
}

.picture-wrapper {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  font-size: 16px;
  line-height: 16px;
  font-family: $open-font;
  text-transform: uppercase;
}

.small-picture app-profile-picture > .picture-wrapper {
  width: 25px;
  height: 25px;
  font-size: 13px;
  line-height: 13px;
}

.router-link {
  cursor: pointer;
}

input[type='checkbox'] {
  position: relative;
  height: 10px;
  width: 10px;
  cursor: pointer;

  &:before {
    position: absolute;
    content: '';
    border: 1px solid black;
    border-radius: 3px;
    left: -1px;
    top: -1px;
    bottom: -1px;
    right: -1px;
    background-color: $bg-lightest-grey;
  }

  &:checked {
    &:after {
      position: absolute;
      content: '';
      border-radius: 3px;
      left: 1px;
      top: 1px;
      bottom: 1px;
      right: 1px;
      background-color: $light-blue;
    }
  }
}

@each $name, $color in $color-map {
  .#{'' + $name}-bg {
    background-color: $color !important;
  }
  .#{'' + $name}-bd {
    border-color: #{$color};
  }
  .#{'' + $name}-text {
    color: $color !important;
  }
  .#{'' + $name}-fill {
    fill: $color !important;
  }
  .#{'' + $name}-stroke {
    stroke: $color !important;
  }
  .#{'' + $name}-color {
    --color: #{$color};
  }
  .#{'' + $name}-text-color {
    --text-color: #{$color};
  }
  .#{'' + $name}-background-color {
    --background-color: #{$color};
  }
}

input.hide {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.media-list {
  padding: 20px;

  &.type-top {
    background: $yellow;

    app-media-slider {
      margin: 0 -20px;
    }
  }

  h5 {
    padding: 0 20px;
    color: var(--color, #{$green});
    font-family: $bnp-font;
    font-size: 17px;
    font-style: normal;
    margin: 20px 0 0;

    b {
      color: $darkest-green;
      font-weight: 700;
      font-size: 23px;
    }

    app-icon {
      height: 21px;
      width: 21px;
      margin: 0 4px;

      &.big {
        height: 25px;
        width: 25px;
        margin: 0 2px;
      }
    }
  }
}
.full-size {
  width: 100%;
  height: 100%;
}

select.large {
  width: 230px;
  border: 1px solid $light-green;
  padding: 6px 20px;
  border-radius: 4px;
  display: block;
  margin: 0 0 10px 0;
  font-family: $open-font;
  font-size: 12px;
  font-weight: bold;

  &:hover,
  option {
    cursor: pointer;
  }
}

.rotate-90 {
  transform: rotate(90deg);
}

.underline-menu {
  padding: 0 10px;
  color: $darkest-green;

  a {
    display: inline-block;
    font-family: $bnp-font;
    font-size: 15px;
    font-weight: 700;
    color: $darkest-green;
    padding: 0 6px 6px;
    margin: 0 20px 6px;
    cursor: pointer;

    &.active {
      margin-bottom: 0;
      border-bottom: 6px solid $dark-green;
    }
  }
}

.index-template {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-lightest-grey;
}

.index-template-wrapper {
  position: relative;
  width: 1062px;
  max-width: 60%;

  img {
    position: relative;
    width: 100%;
    max-width: 100%;
    z-index: 1;
    animation: logo-animation 2s linear infinite alternate;
  }

  @keyframes logo-animation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}

$ripple-color: black;
$ripple-opacity: 0.08;

@keyframes bounce{
  0% {
    box-shadow: 0 0 0 0rem rgba($ripple-color, $ripple-opacity),
    0 0 0 1rem rgba($ripple-color, $ripple-opacity),
    0 0 0 3rem rgba($ripple-color, $ripple-opacity);
  }
  100% {
    box-shadow: 0 0 0 1rem rgba($ripple-color, $ripple-opacity),
    0 0 0 3rem rgba($ripple-color, $ripple-opacity),
    0 0 0 5rem rgba($ripple-color, 0);
  }
}

.expandable {
  transition: max-height 250ms;
  overflow: hidden;

  &[hidden] {
    max-height: 0 !important;
  }
}
